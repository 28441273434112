import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import useHover from '@assets/scripts/hooks/useHover';

// data
import i18n from '@data/i18n';
import { home as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/LinkPage';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
import Cta from '@components/molecules/Cta';
import ItemArticle from '@components/molecules/items/ItemArticle';
import Intro from '@components/organisms/Intro';
import ListClients from '@components/organisms/ListClients';
import ListTeam from '@components/organisms/ListTeam';
import ListProjects from '@components/organisms/ListProjects';
import gifFwt from '@assets/images/projects/thumbs/SQUARE-FWT.gif';

// --------------------------------
// #region data
// --------------------------------

const pageClass = 'page-homepage';

const language = 'en';

const seo = {
	title: 'Superhuit – Swiss Digital Agency - Lausanne & Geneva',
	description:
		'Digital communication and web solutions agency focused on the impact of brands. Advice, strategies, websites, e-commerce or application and brand creation.',
};

const relatedLinks = [
	{ title: 'Agency', url: '/en/agency/' },
	{ title: 'Work', url: '/en/projects/' },
];

const IndexPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				wpgraphql {
					posts(
						first: 3
						where: {
							language: EN
							orderby: { field: DATE, order: DESC }
							parent: null # only get root posts, no children
						}
					) {
						nodes {
							id
							databaseId
							uri
							title
							date
						}
					}
				}
				projectImages: allFile(
					filter: {
						relativePath: {
							in: [
								"projects/thumbs/SQUARE-Ackermann.jpg"
								"projects/thumbs/PORTRAIT-Vidy.jpg"
								"projects/thumbs/PORTRAIT-ECA.jpg"
							]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 632, quality: 90) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
				teamImages: allFile(
					filter: {
						relativePath: {
							in: [
								"team/angela.jpg"
								"team/luc.jpg"
								"team/felipe.jpg"
								"team/sylvain.jpg"
								"team/charlotte.jpg"
								"team/alexandre.jpg"
								"team/kilian.jpg"
								"team/simeon.jpg"
								"team/samuel.jpg"
								"team/pierrick.jpg"
								"team/emilien.jpg"
								"team/fabien.jpg"
								"team/hugonew.jpg"
								"team/steve.jpg"
								"team/julie.jpg"
								"team/nadine.jpg"
								"team/raphael.jpg"
								"team/arno.jpg"
							]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 228, quality: 90) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		`
	);

	// Extract the data from the GraphQL query results
	const {
		wpgraphql: {
			posts: { nodes },
		},
	} = data;

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const projectsList = [
		{
			link: '/en/projects/eca-vaud/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-ECA.jpg'
			),
			title: 'ECA Vaud',
			subtitle: 'UI concept – Design – Web development - Workshops',
		},
		{
			link: '/en/projects/fwt-freeride-world-tour/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifFwt,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 496 / 496, // width / height
			},
			title: 'FWT - Freeride World Tour',
			subtitle: 'UI concept - Design - Web development - Workshops',
			linkedProjects: [
				{
					link: '/en/projects/fwt-freeride-world-tour-branding/',
					title: 'Discover FWT rebranding',
				},
			],
		},
		{
			link: '/en/projects/ackermann-branding/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-Ackermann.jpg'
			),
			title: 'Ackermann',
			subtitle: 'Branding – Brand positionning – Packaging',
			linkedProjects: [
				{
					link: '/en/projects/ackermann-typography/',
					title: 'Ackermann handcrafted typography',
				},
			],
		},
		{
			link: '/en/projects/theatre-de-vidy-lausanne/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-Vidy.jpg'
			),
			title: 'Théâtre de Vidy-Lausanne',
			subtitle: 'UX Design – Digital Design – Web Development',
		},
	];

	const teamMembers = [
		{
			name: 'Emilien',
			role: 'Visual Designer',
			image: data.getImageAttributes('teamImages', 'team/emilien.jpg'),
			link: '/todo',
		},
		{
			name: 'Charlotte',
			role: 'Frontend Developer',
			image: data.getImageAttributes('teamImages', 'team/charlotte.jpg'),
			link: '/todo',
		},
		{
			name: 'Fabien',
			role: 'Founder, Strategist',
			image: data.getImageAttributes('teamImages', 'team/fabien.jpg'),
			link: '/todo',
		},
		{
			name: 'Raphaël',
			role: 'Client Partner',
			image: data.getImageAttributes('teamImages', 'team/raphael.jpg'),
			link: '/todo',
		},
		{
			name: 'Hugo',
			role: 'Partner, Fullstack Developer',
			image: data.getImageAttributes('teamImages', 'team/hugonew.jpg'),
			link: '/todo',
		},
		{
			name: 'Samuel',
			role: 'Partner, Business Developer',
			image: data.getImageAttributes('teamImages', 'team/samuel.jpg'),
			link: '/todo',
		},
		{
			name: 'Felipe',
			role: 'Fullstack Developer',
			image: data.getImageAttributes('teamImages', 'team/felipe.jpg'),
			link: '/todo',
		},
		{
			modifiers: 'statistic',
			name: 'happy humans',
			statistic: '17',
			link: '/en/team/',
		},
	];

	// interaction
	const [hoverOn, hoverOff] = useHover({ dotScale: 0.8 });

	return (
		<Layout
			pageClass={pageClass}
			language={language}
			footerRelatedLinks={relatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/', lang: 'fr' }]}
			seo={seo}
		>
			<Intro
				title={
					<>
						Interactive brands
						<br />
						that people love
						<br />
						online and beyond
					</>
				}
				subtitle="Swiss digital agency – Web & Branding in Lausanne and Genève"
				servicesTitle="We amplify"
				services={[
					{
						text: 'Strategy',
						link: '/en/digital-strategy-agency/',
						alt: 'Swiss Digital agency – Strategy',
					},
					{
						text: 'Branding',
						link: '/en/swiss-branding-agency/',
						alt: 'Branding Agency',
					},
					{
						text: 'Web Solutions',
						link: '/en/swiss-web-agency/',
						alt: 'Swiss Web Agency',
					},
				]}
			/>

			<section className="grid" aria-label="Moto">
				<div className="box text-content" data-animation-page>
					<h2 className="h2-like-uptitle">Swiss digital agency</h2>
					<p className="p-like-h2">
						We create{' '}
						<Link
							to="/en/swiss-branding-agency/"
							data-target
							onMouseEnter={() => hoverOn()}
							onMouseLeave={() => hoverOff()}
						>
							winning brands
						</Link>{' '}
						and{' '}
						<Link
							to="/en/swiss-web-agency/"
							data-target
							onMouseEnter={() => hoverOn()}
							onMouseLeave={() => hoverOff()}
						>
							digital&nbsp;solutions
						</Link>
						.
					</p>
				</div>
			</section>

			<section aria-label="Projects">
				<SectionTitle
					title="Our projects speak louder than words"
					className="section-title--more-margin"
				/>
				<div className="grid">
					<ListProjects
						data-animation-page
						projectsList={projectsList}
						link={{
							title: 'See more projects',
							url: '/en/projects/',
						}}
					/>
				</div>
			</section>

			<section className="grid" aria-label="Clients">
				<div
					className="box text-center text-content"
					data-animation-page
				>
					<p className="uptitle">Who’s put their trust in us?</p>
					<ListClients clientsList={clientsList} />
				</div>
				<Cta
					text="You have an idea that you want to get off the ground?"
					cta="Let’s chat"
					url="/en/contact/"
				/>
			</section>

			<section
				className="grid-content grid text-content"
				aria-label="Team"
				data-animation-page
			>
				<h2>The talented characters behind our projects</h2>

				<ListTeam
					modifiers="zigzag"
					className={['justify-center', 'col-full']}
					teamMembers={teamMembers}
				/>

				<ButtonPrimary
					className="col-full element-center"
					href="/en/team/"
					text="Meet the team"
				/>
			</section>

			{nodes && !!nodes.length && (
				<section
					className="grid-content grid text-content"
					aria-label="Articles"
					data-animation-page
				>
					<h2>Most recent articles</h2>
					<div className="text-content element-spacing">
						{nodes.map((post) => (
							<ItemArticle
								key={post.id}
								link={{
									title: i18n[language].readPost,
									url: post.uri,
								}}
								title={post.title}
							/>
						))}
					</div>
				</section>
			)}

			{/* <section className="grid" aria-label="Newsletter">
				<Cta
					subscribe
					text="Become a superhuit insider"
					cta="Sign me up"
					currentPage="Home"
				/>
			</section> */}
		</Layout>
	);
};

export default IndexPage;
